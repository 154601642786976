import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
Vue.use(VueRouter)

const routes = [
  ...router_modular
]

const router = new VueRouter({
  mode: 'history',
  routes
})
//全局路由守卫
router.beforeEach((to,from,next)=>{
  // console.log(to.query.t,from);
  window.sessionStorage.setItem("token",to.query.t)
  window.sessionStorage.setItem("e",to.query.e) // e=1调正式接口  ， e=2调测试接口
  next()
})
router.afterEach((to,from)=>{
  console.log(to,from);
})
export default router
